import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroBanner from "../components/hero-banner";

export default ({ data }) => (
  <Layout>
    <SEO
      title="Products"
      keywords={[
        "Busway",
        "System",
        "Synchronizing",
        "Panel",
        "Commercial",
        "Automation",
        "Industrial",
        "Generator Set",
        "Control",
        "Automatic",
        "Transfer",
        "Switch",
        "Manual",
        "Motor",
        "Center",
        "Control",
        "comap",
        "megaduct",
        "Distribution",
        "Boards"
      ]}
    />
    <HeroBanner
      desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
      desktop={data.bgDesktop.childImageSharp.fluid.src}
      tablet={data.bgTablet.childImageSharp.fluid.src}
      mobile={data.bgMobile.childImageSharp.fluid.src}
      mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
    >
      <h1 className="text-white">Our Products</h1>
    </HeroBanner>

    <div className="container space-2 space-3-bottom--lg">
      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 order-md-2 mb-5 mb-md-0">
          <div className="pl-md-4">
            <h3 className="h3">Busway System</h3>
            <p>
              In electrical power distribution, a busway system also often
              called bus duct system is a duct which contains a conductor inside
              that is either copper or aluminium. Most often it is housed with
              either steel ducting or extruded aluminium. It is an alternative
              means in conducting and distributing electricity rather than using
              the traditional cable systems.
            </p>
          </div>
        </div>

        <div className="col-md-6 order-md-1">
          <Img
            className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
            fluid={data.busduct.childImageSharp.fluid}
            alt="Busway System"
            title="Busway System"
          />
        </div>
      </div>

      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 mb-5 mb-md-0">
          <div className="pl-md-4">
            <h3 className="h3">Synchronizing Panel</h3>
            <p>
              Synchronisation of Generator sets and breakers are very important
              in order to transfer the power supply from normal to emergency
              once the main supply of power is interrupted. After an initial
              warm-up period the generators will synchronise with each other.
              The load share units continuously monitor the load and during low
              demand periods one or two generators will be shut down for fuel
              economy. As power demand increases again the second generator and
              third generator will be restarted, synchronised and will be
              reconnected to the load. When the ComAp controller detects that
              the main supply has been restored an adjustable period for
              observation is to be done before the main supply is reconnected. A
              cool down period will then follow, after which the generator sets
              will be shut down.
            </p>
          </div>
        </div>

        <div className="col-md-6">
          <Img
            className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
            fluid={data.control.childImageSharp.fluid}
            alt="Static Power"
            title="Static Power"
          />
        </div>
      </div>

      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 order-md-2  mb-5 mb-md-0">
          <div className="pr-md-4">
            <h2>Commercial and Industrial Automation System</h2>
            <p>
              Building management system is very essential particularly in
              building maintenance and building cost efficiency. It will also
              reduce power consumption which then reduce green house effect that
              is caused by carbon emission.
            </p>
            <p>
              Industrial automation is the use of control systems for processes
              and machineries to assist humans and reduce manual labor. These
              are often used in factories, farms and other industries.
            </p>
          </div>
        </div>

        <div className="col-md-6 order-md-1">
          <Img
            className="img-fluid mx-auto d-block p-3 mb-5 bg-white rounded"
            fluid={data.WebSupervisor.childImageSharp.fluid}
            alt="Static Power"
            title="Static Power"
          />
        </div>
      </div>

      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 mb-5 mb-md-0">
          <div className="pl-md-4">
            <h3 className="h3">Generator Set Control Panel</h3>
            <p>
              The function of the Generator control panel is to automatically
              start and stop a Generator’s engine, This is very important to
              protect your Generator from further damage when a problem occurs.
              You can also monitor important parameters like oil pressure and
              coolant temperature through this control panel.
            </p>
          </div>
        </div>

        <div className="col-md-6">
          <Img
            className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
            fluid={data.genset.childImageSharp.fluid}
            alt="Static Power"
            title="Static Power"
          />
        </div>
      </div>

      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 order-md-2  mb-5 mb-md-0">
          <div className="pr-md-4">
            <h2>Automatic/Manual Transfer Switch</h2>
            <p>
              In the event that the main source of power has an unexpected
              outage, The transfer switch transfer’s the power from the primary
              source to a secondary or a backup source, this can be done
              automatically or manually and once the power from the grid or the
              main power source is restored, the transfer switch
              automatically/manually transfer the supply back to the main
              source.
            </p>
          </div>
        </div>

        <div className="col-md-6 order-md-1">
          <Img
            className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
            fluid={data.ats.childImageSharp.fluid}
            alt="Static Power"
            title="Static Power"
          />
        </div>
      </div>

      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 mb-5 mb-md-0">
          <div className="pl-md-4">
            <h3 className="h3">Motor Control Center</h3>
            <p>
              A panel assembly that controls electrical motors in a central
              location. It consists of vertical enclosed sections that have a
              common electrical bus and each section contains motor starters,
              circuit breakers, fuses and power disconnect which is collectively
              called a combination starter.
            </p>
          </div>
        </div>

        <div className="col-md-6">
          <Img
            className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
            fluid={data.control.childImageSharp.fluid}
            alt="Static Power"
            title="Static Power"
          />
        </div>
      </div>

      <div className="row align-items-md-center space-1-bottom space-2-bottom--lg">
        <div className="col-md-6 order-md-2  mb-5 mb-md-0">
          <div className="pr-md-4">
            <h2>Distribution Boards</h2>
            <p>
              An electrical panel board that distributes power feed to
              subsidiary circuits while protecting circuit breakers and fuses
              for each circuits in a common enclosure. Normally arranged with a
              main circuit breaker and feeder circuit breakers.
            </p>
          </div>
        </div>

        <div className="col-md-6 order-md-1">
          <Img
            className="img-fluid mx-auto d-block shadow-lg p-3 mb-5 bg-white rounded"
            fluid={data.distribution.childImageSharp.fluid}
            alt="Distribution Boards"
            title="Distribution Boards"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    control: file(relativePath: { eq: "comap/intelivision.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 700, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    busduct: file(relativePath: { eq: "products/busduct.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 700, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    ats: file(relativePath: { eq: "products/ats.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 700, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    genset: file(relativePath: { eq: "products/genset.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 700, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    distribution: file(relativePath: { eq: "products/distribution.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 700, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    WebSupervisor: file(relativePath: { eq: "products/WebSupervisor.png" }) {
      childImageSharp {
        fluid(maxHeight: 700, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktopWide: file(relativePath: { eq: "products-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "products-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "products-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "products-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "products-bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
