import React, { Fragment } from "react";

const HeroBanner = ({
  desktopWide,
  desktop,
  tablet,
  mobile,
  mobileXS,
  children
}) => {
  return (
    <Fragment>
      <div className="gradient-overlay-half-dark-v2 bg-img-hero hero">
        <div className="container space-2 space-3-top--lg space-2-bottom--lg">
          <div className="w-lg-80 text-center mx-lg-auto">{children}</div>
        </div>
      </div>

      <style jsx="true">{`
        .hero {
          background-image: url(${desktopWide});
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          .hero {
            background-image: url(${desktop});
          }
        }
        @media (min-width: 768px) and (max-width: 992px) {
          .hero {
            background-image: url(${tablet});
          }
        }
        @media (min-width: 576px) and (max-width: 768px) {
          .hero {
            background-image: url(${mobile});
          }
        }
        @media (max-width: 576px) {
          .hero {
            background-image: url(${mobileXS});
          }
        }
      `}</style>
    </Fragment>
  );
};

export default HeroBanner;
